(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash.filter"), require("lodash.values"), require("lodash.reduce"), require("vuex"), require("leaflet"), require("vue"), require("lodash.foreach"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash.filter", "lodash.values", "lodash.reduce", "vuex", "leaflet", "vue", "lodash.foreach"], factory);
	else if(typeof exports === 'object')
		exports["mapp-kit-leaflet"] = factory(require("lodash.filter"), require("lodash.values"), require("lodash.reduce"), require("vuex"), require("leaflet"), require("vue"), require("lodash.foreach"));
	else
		root["mapp-kit-leaflet"] = factory(root["lodash.filter"], root["lodash.values"], root["lodash.reduce"], root["vuex"], root["leaflet"], root["vue"], root["lodash.foreach"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__2a5e__, __WEBPACK_EXTERNAL_MODULE__376f__, __WEBPACK_EXTERNAL_MODULE__3f12__, __WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__860c__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_af61__) {
return 