(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@vue-mapp-kit/leaflet"), require("vuex"), require("leaflet.markercluster"), require("esri-leaflet-cluster"), require("esri-leaflet"));
	else if(typeof define === 'function' && define.amd)
		define(["@vue-mapp-kit/leaflet", "vuex", "leaflet.markercluster", "esri-leaflet-cluster", "esri-leaflet"], factory);
	else if(typeof exports === 'object')
		exports["mapp-kit-esri-leaflet"] = factory(require("@vue-mapp-kit/leaflet"), require("vuex"), require("leaflet.markercluster"), require("esri-leaflet-cluster"), require("esri-leaflet"));
	else
		root["mapp-kit-esri-leaflet"] = factory(root["@vue-mapp-kit/leaflet"], root["vuex"], root["leaflet.markercluster"], root["esri-leaflet-cluster"], root["esri-leaflet"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__2721__, __WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE_a388__, __WEBPACK_EXTERNAL_MODULE_c7b8__, __WEBPACK_EXTERNAL_MODULE_d3d4__) {
return 