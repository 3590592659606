export const VUEAFLET_CREATE_MAP = 'VUEAFLET_CREATE_MAP';
export const VUEAFLET_ADD_MAP_LAYER = 'VUEAFLET_ADD_MAP_LAYER';
export const VUEAFLET_ADD_NAMED_LAYER = 'VUEAFLET_ADD_NAMED_LAYER';
export const VUEAFLET_REMOVE_MAP_LAYER = 'VUEAFLET_REMOVE_MAP_LAYER';
export const VUEAFLET_REMOVE_NAMED_LAYER = 'VUEAFLET_REMOVE_NAMED_LAYER';
export const VUEAFLET_ADD_MAP_EVENT = 'VUEAFLET_ADD_MAP_EVENT';
export const VUEAFLET_SET_VIEW = 'VUEAFLET_SET_VIEW';
export const VUEAFLET_ADD_TO_MAP = 'VUEAFLET_ADD_TO_MAP';
export const VUEAFLET_PAN_TO = 'VUEAFLET_PAN_TO';
export const VUEAFLET_PM_ENABLE_DRAW = 'VUEAFLET_PM_ENABLE_DRAW';
export const VUEAFLET_PM_DISABLE_DRAW = 'VUEAFLET_PM_DISABLE_DRAW';
export const VUEAFLET_PM_ADD_SHAPE_LAYER = 'VUEAFLET_PM_ADD_SHAPE_LAYER';
export const VUEAFLET_PM_REMOVE_SHAPE_LAYERS = 'VUEAFLET_PM_REMOVE_SHAPE_LAYERS';
export const VUEAFLET_RESET = 'VUEAFLET_RESET';
export const VUEAFLET_INVALIDATE = 'VUEAFLET_INVALIDATE';
